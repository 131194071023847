import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { store } from './store';
import BaseLayout from './components/base/BaseLayout.vue';
import GStore from './store/global.store'
import TranslatePlugin from './plugins/lang/translate-plugin';
import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/core.css'
import ApiService from "./services/api.service";
import { TokenService } from "./services/token.service";

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .provide('GStore', GStore)
  .use(store)
  .use(TranslatePlugin);

// ApiService.init("https://lumitel.app");
ApiService.init("https://profitimpulse.com");
// ApiService.init("http://localhost:8000");
// ApiService.init(process.env.VUE_APP_ROOT_API); // For testing with mobile phone on local network
// ApiService.init("http://192.168.1.191:8000");

if (TokenService.getToken()) {
  ApiService.setHeader();
  ApiService.mountRequestInterceptor();
  ApiService.mount401Interceptor();
}

app.component('base-layout', BaseLayout);

router.isReady().then(() => {
  app.mount('#app');
});
